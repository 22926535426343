<template>
  <v-form>
    <v-select
      v-model="$v.form.subject.$model"
      :error-messages="getErrors('form.subject')"
      required
      :items="subjects"
      label="Предмет"
    ></v-select>

    <v-select
      v-model="$v.form.grade.$model"
      :error-messages="getErrors('form.grade')"
      required
      :items="grades"
      label="Класс"
    ></v-select>

    <v-select
      v-model="form.is_final"
      :error-messages="getErrors('form.is_final')"
      label="Итоговая работа"
      :items="[{text: 'Нет', value: 0}, {text: 'Да', value: 1}]"
      name="is_final"
      :color="$const.color.primary" 
    ></v-select>
    
    <a href="/upload/file_templates/themes.csv" download="themes.csv" class="caption">Скачать шаблон списка тем</a>
    <v-file-input
      v-model="$v.form.themesFile.$model"
      :error-messages="getErrors('form.themesFile')"
      show-size
      accept=".csv"
      label="Файл списка тем (csv)"
    ></v-file-input>

    <a href="/upload/file_templates/tasks_to_themes.csv" download="tasks_to_themes.csv" class="caption">Скачать шаблон списка заданий к темам</a>
    <v-file-input
      v-model="$v.form.taskToThemesFile.$model"
      :error-messages="getErrors('form.taskToThemesFile')"
      show-size
      accept=".csv"
      label="Файл списка заданий к темам (csv)"
    ></v-file-input>
    
    <template v-if="serverErrors && serverErrors.summary" >
      <v-alert dense type="error">
        {{ serverErrors.summary }}
      </v-alert>
    </template>

    <v-divider class="my-4" />
    <div class="d-flex flex-column">
      <div>
        <v-btn dark :color="$const.color.primary" :loading="waiting" @click.prevent.stop="dialogShow = true">
          Загрузить
        </v-btn>
      </div>
      <span class="caption text--grey">Настройки csv файлов: разделитель ячеек - вертикальная черта (pipe) - <code>|</code>. Разделитель текста - двойные кавычки <code>"</code>.</span>
      <span class="caption text--grey">Внимание: {{ warningText }}</span>
    </div>

    <v-dialog v-model="dialogShow" max-width="500px">
      <v-card>
        <v-card-title class="headline">Внимание</v-card-title>
        <v-card-text>{{ warningText }} Подобного рода операция рекомендована только для первичной загрузки данных.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark :color="$const.color.primary" @click="dialogShow = false">Отмена</v-btn>
          <v-btn dark :color="$const.color.primary" outlined @click="submit">Загрузить</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'TaskUploadForm',
  props: {
    model: { type: Object }
  },
  mixins: [errorMixin, saveMixin, validationMixin],
  data () {
    return {
      dialogShow: false,
      warningText: 'После загрузки указанных файлов произойдет полное удаление данных тем для заданий по указанному сочетанию предметов и классов. Действие нельзя будет обратить.',
      form: {
        subject: null,
        grade: null,
        is_final: 0,
        themesFile: null,
        taskToThemesFile: null
      },
      waiting: false
    };
  },
  validations() {
    return {
      form: {
        subject: { required },
        grade: { required },
        themesFile: { required },
        taskToThemesFile: { required },
      }
    }
  },
  computed: {
    ...mapState('task', ['subjects']),
    ...mapGetters('task', ['grades'])
  },
  methods: {
    async submit () {
      this.dialogShow = false;

      if (!this.validate()) {
        console.log('Validation failed');
        return false;
      }
      this.waiting = true;
      this.serverErrors = null;

      let form = new FormData();
      _.forOwn(this.form, (v,k) => {
        form.append(k, v);
      })

      const { success, error } = await this.$store.dispatch('task/themesUpload', form);

      if(!success) {
        this.serverErrors = error;
      } else {
        alert('Темы успешно загружены')
        this.$emit('back', 1);
      }

      this.waiting = false;
      return;
    }
  }
}
</script>
