<template>
    <form-view 
        v-slot="{ onSuccess, onBack }"
        title="Загрузка тем для заданий"
        create
        url-back="/task"
    >
        <task-themes-upload-form @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import TaskThemesUploadForm from '@/components/forms/TaskThemesUploadForm.vue'
export default {
    components: { FormView, TaskThemesUploadForm }
}
</script>